import React from 'react'
import * as styles from '../components/views/layout/layoutLoggedOut/authFlowForm/authFlowForm.module.scss'
import { Link, navigate } from 'gatsby'
import * as yup from 'yup'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import { Input } from '../components/common/form/fields'
import { Alert, LoadingOverlay, Seo } from '../components/common'
import { AuthFlowForm, AuthFlowWrapper } from '../components/views/layout'

import { useForm, useLogin } from '../hooks'
import { isLoggedIn } from '../common/utils/auth'
import { Paths } from '../constants/structure'

const schema = yup.object().shape({
   email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email field is required'),
   password: yup.string().required('Password field is required'),
})

export default function LoginPage() {
   const { search } = useLocation()
   const { error: loginError, isLoading, isSuccess, login } = useLogin()
   const { onSubmit, validationErrors } = useForm(schema, (values) => {
      login(values.email, values.password)
   })

   if (isSuccess || isLoggedIn()) {
      const { returnUrl } = parse(search)
      const navigateTo = returnUrl ? String(returnUrl) : Paths.DASHBOARD
      navigate(navigateTo)
      return <LoadingOverlay />
   }

   return (
      <AuthFlowWrapper
         heading="Web Portal"
         callToAction="Welcome! Sign in below to get started."
         footer={
            <div className={styles.trialLink}>
               No account yet?
               <Link to={Paths.REGISTER} className={styles.trialLink}>
                  {' '}
                  Get a Free 14-Day Trial{' '}
               </Link>
               &gt;
            </div>
         }
      >
         <Seo title="Login" />
         <AuthFlowForm
            button={{
               label: 'SIGN IN',
            }}
            isLoading={isLoading}
            onSubmit={onSubmit}
            returnLink={
               <Link
                  to={Paths.FORGOTTEN_PASSWORD}
                  className={styles.returnLink}
               >
                  <strong>Forgot Password?</strong>
               </Link>
            }
         >
            {loginError && <Alert message={loginError} />}

            <Input
               id="email"
               label="Email"
               error={validationErrors.email}
               required
               theme="dark"
            />

            <Input
               id="password"
               inputProps={{
                  type: 'password',
               }}
               label="Password"
               error={validationErrors.password}
               required
               theme="dark"
            />
         </AuthFlowForm>
      </AuthFlowWrapper>
   )
}
